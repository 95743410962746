<template>
  <section id="engagement" class="section section__form">
    <div>
      <h2 v-scroll:reveal="{ delay: 50 }" class="section__form--title">
        Engagement
      </h2>
      <p v-scroll:reveal="{ delay: 150 }" class="section__form--subtitle">
        {{ $c('header.subtitle1') }}
      </p>
      <br />
      <p v-scroll:reveal="{ delay: 200 }" class="section__form--subtitle">
        {{ $c('header.subtitle2') }}
      </p>

      <div v-if="engagements !== null" v-scroll:reveal="{ delay: 125 }" class="header__stat">
        <output class="header__stat-number">
          {{ engagements }}
          <svg
            style="width: 2rem; height: 2rem"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 25.75 21.17"
          >
            <defs>
              <linearGradient id="icongrad" x1="13.14" y1="21.17" x2="12.77" y2="-0.1" gradientUnits="userSpaceOnUse">
                <stop offset="0.07" stop-color="#f7d31c" />
                <stop offset="0.42" stop-color="#77d252" />
                <stop offset="0.82" stop-color="#00b897" />
              </linearGradient>
            </defs>
            <path
              d="M23.59,1.28a6.12,6.12,0,0,0-8.13.59l-2.59,2.6L10.29,1.88A6.11,6.11,0,0,0,2.17,1.3a5.94,5.94,0,0,0-.45,8.77l7.69,7.65,3.45,3.45,1.39-1.39L24,10A5.92,5.92,0,0,0,23.59,1.28Zm-1,7.35-9.76,9.74L3.13,8.66a3.94,3.94,0,0,1,.65-6.08,4,4,0,0,1,5,.61l4.11,4.1,4.18-4.2a3.92,3.92,0,1,1,5.55,5.54Z"
              style="fill:url(#icongrad)"
            />
          </svg>
        </output>

        <p>{{ $c('header.citoyen') }}</p>
      </div>

      <div v-if="shares !== null" v-scroll:reveal="{ delay: 200 }" class="header__stat">
        <output class="header__stat-number">
          {{ shares }}
          <svg
            style="width: 2rem; height: 2rem"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 25.64 11.78"
          >
            <defs>
              <linearGradient id="icongrad1" x1="2.5" y1="8.22" x2="9.26" y2="0.98" gradientUnits="userSpaceOnUse">
                <stop offset="0.12" stop-color="#f7d31c" />
                <stop offset="0.41" stop-color="#77d252" />
                <stop offset="0.84" stop-color="#00b897" />
              </linearGradient>
              <linearGradient id="icongrad2" y1="5.89" x2="25.64" y2="5.89" gradientUnits="userSpaceOnUse">
                <stop offset="0.07" stop-color="#f7d31c" />
                <stop offset="0.43" stop-color="#77d252" />
                <stop offset="0.72" stop-color="#00b897" />
              </linearGradient>
            </defs>
            <path
              d="M9.49,7.79l1.9-1.9L8.64,3.14A3.89,3.89,0,0,0,2,5.89H0A5.89,5.89,0,0,1,10.06,1.73l2.76,2.76Z"
              style="fill:url(#icongrad1)"
            />
            <path
              d="M25.64,5.89a5.89,5.89,0,0,1-10,4.17L12.84,7.3l1.42-1.41L17,8.64a3.89,3.89,0,0,0,6.64-2.75Z"
              style="fill:#00b897"
            />
            <path
              d="M25.64,5.89h-2A3.89,3.89,0,0,0,17,3.14l-1.39,1.4L14.26,5.89,12.84,7.3h0l-2.77,2.77A5.9,5.9,0,0,1,0,5.89H2A3.89,3.89,0,0,0,5.89,9.78,3.83,3.83,0,0,0,8.64,8.64l.85-.85,3.33-3.3h0l2.11-2.12.65-.65A5.89,5.89,0,0,1,25.64,5.89Z"
              style="fill:url(#icongrad2)"
            />
          </svg>
        </output>

        <p>
          {{ $c('header.share') }}
        </p>
      </div>
    </div>
    <div class="header__card-wrapper">
      <div class="header__card-wrapper--card" :class="{ '-closed': !open }">
        <simplebar class="header__scrollbox">
          <HeaderForm :open="open" class="header__form" @topbox="handleOpen" />
        </simplebar>

        <button
          v-if="!sent && open"
          class="button -accent header__form-submit"
          name="Je m’engage"
          @click="handleFormSubmit"
        >
          Je m’engage !
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import simplebar from 'simplebar-vue'
import { HeaderForm } from './partials'

export default {
  name: 'FormSection',
  components: { HeaderForm, simplebar },
  data() {
    return {
      engagements: null,
      shares: null,
      sent: false,
      open: true
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$events.on('openForm', () => (this.open = true))
  },
  methods: {
    handleOpen(e) {
      this.open = e
    },
    getData() {
      this.$firebase.db.ref(`/engagements/count`).on('value', snap => {
        this.engagements = snap.val()
      })
      this.$firebase.db.ref(`/share/count`).on('value', snap => {
        this.shares = snap.val()
      })
    },
    handleFormSubmit() {
      this.$events.emit('formSubmit')
      this.sent = true
    }
  }
}
</script>
