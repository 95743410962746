<template>
  <main v-if="!$route.path.includes('admin')" id="app" class="layout">
    <SiteNavbar @openNav="mobileNav = true" />

    <Transition name="page" mode="out-in" appear @enter="updateScrollPosition" @after-leave="setScrollPosition">
      <RouterView :key="$route.name" class="page layout__view" />
    </Transition>

    <SiteFooter />
    <SiteNavbar v-if="$route.name !== 'blog'" is-sticky @openNav="mobileNav = true" />

    <transition name="fade">
      <SiteMobileNav v-if="mobileNav" @closeNav="mobileNav = false" />
    </transition>
  </main>

  <main v-else id="app">
    <RouterView :key="$route.name" />
  </main>
</template>

<script>
import { SiteNavbar, SiteFooter, SiteMobileNav } from '@/components'

export default {
  name: 'App',
  components: { SiteMobileNav, SiteNavbar, SiteFooter },
  metaInfo: {
    htmlAttrs: { lang: 'fr' },
    title: 'Pour moi, Pour Québec',
    titleTemplate: '%s | CIUSSSCN',
    meta: [
      { property: 'og:locale', content: 'fr_CA' },
      { property: 'og:site_name', content: 'Pour moi, Pour Québec | CIUSSSCN' },
      { property: 'og:type', content: 'website' }
    ]
  },
  data() {
    return {
      scrollPosition: 0,
      mobileNav: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from = {}) {
        if (!to.hash) return
        const delay = to.name !== from.name ? 400 : 0
        setTimeout(() => this.$scroll.toTarget(to.hash, 0, 350), delay)
      }
    }
  },
  methods: {
    updateScrollPosition() {
      this.$nextTick(() => this.$scroll.to(this.scrollPosition))
    },
    setScrollPosition() {
      this.scrollPosition = window.savedScroll || 0
    }
  }
}
</script>
