<template>
  <div>
    <h1>Entête</h1>
    <div v-for="(value, key) in header" :key="key">
      <SimpleTextInput :name="key" :value="header[key]" @input="inputChanged" />
    </div>
  </div>
</template>

<script>
import SimpleTextInput from '@/components/admin/inputs/SimpleTextInput.vue'

export default {
  name: 'Header',
  components: { SimpleTextInput },
  data() {
    return {
      header: {}
    }
  },
  mounted() {
    this.header = { ...this.$content.header }
    Object.keys(this.header.form).forEach(key => {
      this.header[`form.${key}`] = this.header.form[key]
    })
    delete this.header.form
  },
  methods: {
    inputChanged(event) {
      const { name: key, value } = event.target
      this.header[key] = value
      this.$emit('firebaseTextChanged', { key: `header.${key}`, value })
    }
  }
}
</script>
