<template>
  <figure class="header__background" :style="{ backgroundImage }" />
</template>

<script>
import Tween from 'greensock/dist/TweenLite'
import randomNumberBetween from 'random-entities/float'

export default {
  name: 'HeaderGradient',
  data() {
    return {
      range: {
        firstColorStop: [7.5, 15],
        secondColorStop: [30, 60],
        xPos: [0, 60],
        yPos: [80, 100]
      },
      gradient: {
        xPos: null,
        yPos: null,
        firstColorStop: null,
        secondColorStop: null
      }
    }
  },
  computed: {
    backgroundImage() {
      const { xPos, yPos, firstColorStop, secondColorStop } = this.gradient
      return `radial-gradient(at ${xPos}% ${yPos}%, #F7D31C ${firstColorStop}%, #77D252 ${secondColorStop}%, #00B897, #00A1AB)`
    }
  },
  created() {
    Object.keys(this.range).forEach(key => {
      this.gradient[key] = randomNumberBetween(...this.range[key], 1, true)
    })
  },
  mounted() {
    this.setNextOriginAnimation()
    this.setNextStopAnimation('firstColorStop')
    this.setNextStopAnimation('secondColorStop')
  },
  methods: {
    setNextOriginAnimation() {
      const [xMin, xMax] = this.range.xPos
      const [yMin, yMax] = this.range.yPos

      Tween.to(this.gradient, randomNumberBetween(6, 9, 2, true), {
        xPos: randomNumberBetween(xMin, xMax, 2, true),
        yPos: randomNumberBetween(yMin, yMax, 2, true),
        ease: Tween.Quad.easeInOut,
        onComplete: this.setNextOriginAnimation
      })
    },
    setNextStopAnimation(key) {
      const [min, max] = this.range[key]

      Tween.to(this.gradient, randomNumberBetween(4, 7, 2, true), {
        [key]: randomNumberBetween(min, max, 1, true),
        ease: Tween.Quad.easeInOut,
        onComplete: this.setNextStopAnimation,
        onCompleteParams: [key]
      })
    }
  }
}
</script>
