<template>
  <Transition name="overlay">
    <section v-if="isOverlayActive" :class="['overlay', { '-primary-shadow': primaryShadow }]">
      <ScrollBox class="overlay__content" content-class="overlay__content-inner">
        <slot />
      </ScrollBox>
    </section>
  </Transition>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    isActive: { type: Boolean, default: false },
    name: { type: String, default: null },
    primaryShadow: { type: Boolean, default: false }
  },
  computed: {
    isOverlayActive() {
      return this.isActive || this.$route.query.overlay === this.name
    }
  }
}
</script>
