<template>
  <div>
    <h1>Medias Sociaux</h1>
    <div v-for="(value, key) in curator" :key="key">
      <SimpleTextInput :name="key" :value="curator[key]" @input="inputChanged" />
    </div>
  </div>
</template>

<script>
import SimpleTextInput from '@/components/admin/inputs/SimpleTextInput.vue'

export default {
  name: 'Curator',
  components: { SimpleTextInput },
  data() {
    return {
      curator: {}
    }
  },
  mounted() {
    this.curator = { ...this.$content.curator }
  },
  methods: {
    inputChanged(event) {
      const { name: key, value } = event.target
      this.curator[key] = value
      this.$emit('firebaseTextChanged', { key: `curator.${key}`, value })
    }
  }
}
</script>
