<template>
  <div id="page-not-found">
    <header class="header" />
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  beforeMount() {
    if (this.$route.name === 'catch-all') this.$router.replace({ name: 'not-found' })
  }
}
</script>
