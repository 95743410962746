<template>
  <section v-if="shouldRenderSection" class="curator">
    <header class="curator__header">
      <h2 v-scroll:reveal="{ delay: 50 }" class="curator__header--title">
        {{ $c('curator.title') }}
      </h2>

      <div v-scroll:reveal="{ delay: 150 }" class="curator__header--text">
        <p>
          {{ $c('curator.subtitle') }}
        </p>
        <br />
        <p>
          {{ $c('curator.hash') }}
        </p>
      </div>
    </header>

    <DropDown :is-open="curatorReady" content-class="curator__feed">
      <section ref="curator">
        <a href="https://curator.io" target="_blank" class="crt-logo crt-tag" aria-label="Curator.io" rel="noopener" />
      </section>
    </DropDown>

    <button class="button curator__button" name="Voir plus de publication">
      <Icon id="plus" />
      {{ $c('curator.button') }}
    </button>
  </section>
</template>

<script>
export default {
  name: 'Curator',
  data() {
    return {
      shouldRenderSection: process.env.SHOW_CURATOR,
      curatorInstance: null,
      curatorReady: false,
      load: false
    }
  },
  created() {
    this.$scroll.on(() => {
      if (!this.load && this.$scroll.position > 500) {
        this.load = true
        this.initCurator()
      }
    })
  },
  methods: {
    loadCuratorScript() {
      return new Promise(resolve => {
        const script = document.createElement('script')
        script.async = true
        script.src = '//cdn.curator.io/3.1/js/curator.js'
        script.addEventListener('load', resolve)
        document.head.appendChild(script)
      })
    },
    loadCuratorStyles() {
      return new Promise(resolve => {
        const link = document.createElement('link')
        const firstLoadedStylesheet =
          document.head.querySelector('link[rel=stylesheet]') || document.head.querySelector('link')
        link.rel = 'stylesheet'
        link.href = '//cdn.curator.io/3.1/css/curator.css'
        link.addEventListener('load', resolve)
        document.head.insertBefore(link, firstLoadedStylesheet)
      })
    },
    initCurator() {
      if (!this.shouldRenderSection) return

      if (this.curatorInstance) {
        this.curatorReady = true
      } else {
        Promise.all([this.loadCuratorScript(), this.loadCuratorStyles()]).then(() =>
          this.onCuratorLoaded(window.Curator)
        )
      }
    },
    onCuratorLoaded(Curator) {
      if (!Curator) return

      const instance = new Curator.Widgets.Grid({
        feedId: '421ba785-c960-4bcc-b6d0-261dfe4caa8e',
        container: this.$refs.curator
      })

      this.curatorInstance = instance

      instance.on(Curator.Events.FEED_LOADED, () => {
        setTimeout(() => {
          this.curatorReady = true
        }, 750)
      })
    }
  }
}
</script>
