<template>
  <transition name="navbar">
    <nav v-if="isVisible" :class="className">
      <RouterLink to="/" @click.native="$scroll.toTop(0, 275)">
        <img :src="logoUri" alt="PMPQ" class="navbar__logo" width="52" height="52" />
      </RouterLink>

      <template v-if="$device.laptop">
        <ul class="row">
          <li v-if="isSticky" class="navbar__link-bubble" :style="bubbleStyle" />

          <li
            v-for="(label, key) in anchorLinks"
            :key="key"
            :ref="key"
            :class="['navbar__link', { '-active': activeAnchor === key }]"
          >
            <RouterLink v-if="key !== 'blog'" :to="{ name: 'home', hash: `#${key}` }">{{ label }}</RouterLink>
            <RouterLink v-else :to="{ name: 'blog' }">{{ label }}</RouterLink>
          </li>
        </ul>

        <div>
          <a
            href="https://www.youtube.com/channel/UCVtRERklhHKX0VTGRpL-mIA"
            aria-label="Youtube"
            target="_blank"
            rel="noopener"
            class="navbar__social"
          >
            <Icon id="youtube" />
          </a>
          <a
            href="https://www.facebook.com/CIUSSSCN/"
            aria-label="Facebook"
            target="_blank"
            rel="noopener"
            class="navbar__social"
          >
            <Icon id="facebook" />
          </a>
          <a
            href="https://www.instagram.com/explore/tags/pourmoipourquebec"
            aria-label="Instagram"
            target="_blank"
            rel="noopener"
            class="navbar__social"
          >
            <Icon id="instagram" />
          </a>
          <a
            href="https://www.linkedin.com/company/ciusss-de-la-capitale-nationale/"
            aria-label="LinkedIn"
            target="_blank"
            rel="noopener"
            class="navbar__social"
          >
            <Icon id="linkedin" />
          </a>
        </div>
      </template>

      <template v-else>
        <div class="row -mobile">
          <button class="button -icon -nav" @click="goToChart"><Icon id="heart" /></button>
          <div class="mobilenav__button" :class="{ '-white': !isSticky }" @click="$emit('openNav')">
            <Icon id="bars" class="mobilenav__button--icon" />
            Menu
          </div>
        </div>
      </template>
    </nav>
  </transition>
</template>

<script>
const activeAnchorThreshold = window.innerHeight * 0.5

export default {
  name: 'SiteNavbar',
  props: {
    isSticky: { type: Boolean, default: false }
  },
  data() {
    return {
      isVisible: !this.isSticky,
      activeAnchor: '',
      anchorElements: [],
      bubbleStyle: { display: 'none' },
      anchorLinks: {
        blog: 'Conseils du jour',
        engagement: 'Engagement',
        testimonials: 'Témoignages',
        stats: 'Tendance',
        references: 'Liens utiles'
      }
    }
  },
  computed: {
    className() {
      return ['navbar', { '-sticky': this.isSticky, '-policy': this.$route.name === 'policy' }]
    },
    logoUri() {
      return this.isSticky ? '/images/logo-gradient.svg' : '/images/logo.svg'
    }
  },
  watch: {
    isVisible(isVisible) {
      if (isVisible) this.$nextTick(this.setBubbleStyle)
    },
    '$route.name': {
      immediate: true,
      handler(toRoute, fromRoute) {
        if (toRoute === fromRoute) return
        setTimeout(() => {
          this.setAnchorElements()
          this.onScroll()
        }, 400)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isSticky) this.$watch('activeAnchor', this.setBubbleStyle, { immediate: true })
      this.$scroll.on(this.onScroll)
    })

    window.addEventListener(
      'resize',
      () => {
        this.setBubbleStyle()
      },
      { passive: true }
    )
  },
  beforeDestroy() {
    this.$scroll.off(this.onScroll)
  },
  methods: {
    setAnchorElements() {
      this.anchorElements = Object.keys(this.anchorLinks)
        .map(hash => ({ hash, node: document.getElementById(hash) }))
        .filter(({ node }) => node)
    },
    goToChart() {
      if (this.$route.name === 'home') {
        if (this.$device.laptop) {
          this.$scroll.to(window.innerHeight * 2, 0, 250)
        } else {
          this.$scroll.to(window.innerHeight, 0, 250)
        }
      } else {
        this.$router.replace({ name: 'home' }).then(() => {
          if (this.$device.laptop) {
            this.$scroll.to(window.innerHeight * 2, 0, 250)
          } else {
            this.$scroll.to(window.innerHeight, 0, 250)
          }
        })
      }
    },
    setBubbleStyle() {
      const [linkNode] = this.$refs[this.activeAnchor] || []
      const offset = linkNode ? linkNode.getBoundingClientRect().left : null

      this.bubbleStyle =
        !this.$device.laptop || !linkNode
          ? { display: 'none' }
          : { width: `${linkNode.clientWidth + 8}px`, transform: `translate3d(${offset - 4}px, -50%, 0)` }
    },
    onScroll() {
      const { hash = '' } =
        [...this.anchorElements]
          .reverse()
          .find(({ node }) => node && node.getBoundingClientRect().top < activeAnchorThreshold) || {}

      this.activeAnchor = hash

      if (this.isSticky) {
        const breakpoint = this.$device.phone ? window.innerHeight * 1.5 : window.innerHeight
        this.isVisible = this.$route.name === 'home' && this.$scroll.position >= breakpoint
      }
    }
  }
}
</script>
