<template>
  <div class="policy">
    <h1>
      Mention légale et conditions d’utilisation de la plateforme d’engagement web du CIUSSS de la Capitale-Nationale
    </h1>
    <p>
      L'utilisation La plateforme d’engagement Web du CIUSSS de la Capitale-Nationale (ci-après le « site ») implique
      l'acceptation des conditions d'utilisation énoncées dans ce document (ci-après les « conditions d'utilisation »).
      Celles-ci pourraient être modifiées à tout moment. Il est donc conseillé de s'y référer régulièrement.
    </p>
    <p>
      Le site permet aux utilisateurs de créer et de diffuser leurs propres histoires, témoignages et photos et de les
      partager par son entremise. Dans la présente entente, tout le contenu fourni par les utilisateurs est désigné
      comme étant le « contenu utilisateur ».
    </p>
    <p>
      Le CIUSSS de la Capitale-Nationale ne revendique aucun droit de propriété sur le contenu utilisateur transmis,
      mais se réserve le droit de le modifier, de l’utiliser et de le publier à sa discrétion dans le cadre de
      l’exploitation du site.
    </p>
    <p>
      Par conséquent, en contribuant au site par la transmission de contenu utilisateur, vous accordez automatiquement
      au CIUSSS de la Capitale-Nationale une licence irrévocable et perpétuelle, non exclusive, transférable,
      entièrement payée et sans redevances et mondiale, permettant du CIUSSS de la Capitale-Nationale ou permettant à
      d’autres d’utiliser, de copier, de distribuer, d’exécuter publiquement, d’afficher publiquement, d’imprimer, de
      publier, de republier, de faire des extraits (en tout ou en partie), de reformater, de traduire, de modifier, de
      réviser et d’incorporer dans d’autres œuvres ce contenu utilisateur et toutes les œuvres dérivées de ce contenu
      utilisateur, sous quelque forme que ce soit en ce qui a trait au support ou à l’expression, de la manière dont le
      service permet de temps à autre l’utilisation du contenu utilisateur, conformément à toutes les lois applicables,
      et d’octroyer des licences ou de permettre à d’autres de faire les actions mentionnées ci-haut. Les droits du
      CIUSSS de la Capitale-Nationale en vertu de cette licence demeurent en vigueur même après la résiliation de la
      présente entente.
    </p>
    <p>
      Le CIUSSS de la Capitale-Nationale se réserve le droit de modifier, corriger, adapter, traduire et créer des
      œuvres dérivées à partir du contenu utilisateur fourni lorsqu’il le traite pour utilisation et diffusion sur le
      site.
    </p>
    <p>
      Le CIUSSS de la Capitale-Nationale se réserve également le droit de refuser de publier ou de retirer le contenu
      utilisateur du site en tout temps, sans préavis, pour quelque raison que ce soit ou sans motif, à sa seule
      discrétion.
    </p>
    <p>
      En tant qu’utilisateur, vous déclarez et garantissez au CIUSSS de la Capitale-Nationale que a) vous êtes le seul
      propriétaire, auteur et titulaire du droit d’auteur du contenu utilisateur que vous apportez au site en tant que
      contribution ou que vous avez obtenu la permission écrite de l’auteur, du propriétaire ou du titulaire du droit
      d’auteur de mettre ce contenu utilisateur à la disposition du service en tant que «contenu utilisateur», que b) ce
      contenu utilisateur n’empiète sur aucun droit de tiers (y compris, sans s’y limiter, les droits d’auteur, les
      brevets, les marques de commerce, les secrets commerciaux, les autres droits de propriété intellectuelle, les
      droits moraux, les droits relatifs à la protection des renseignements personnels ou les droits de publicité), que
      c) le contenu utilisateur est conforme aux modalités de la présente entente et ne contient aucun matériel
      diffamatoire, calomnieux ou obscène, que d) le contenu utilisateur n’enfreint aucune loi ou réglementation
      applicable et ne contient aucune fausse déclaration ou déclaration trompeuse, et que e) vous avez le consentement
      écrit, la décharge, ou la permission de chaque personne physique identifiable, ou du propriétaire ou auteur d’un
      objet ou d’une œuvre présent dans ce contenu utilisateur, d’utiliser le nom ou l’image de chacune de ces personnes
      physiques identifiables, de cet objet ou de cette œuvre, pour permettre l’inclusion et l’utilisation de ce contenu
      utilisateur de la manière envisagée par le service et la présente entente.
    </p>
    <p>
      Sauf indication contraire expresse, le site et tous les textes, images, marques, logos et autre contenu qu’il
      contient, y compris, sans s’y limiter, le logo et toutes les conceptions, graphiques, photos, informations,
      données, logiciels, fichiers sonores, autres fichiers, le contenu et la sélection et la disposition de celui-ci
      sont la propriété exclusive du CIUSSS de la Capitale-Nationale ou de ses concédants de licence et sont protégés
      par les lois canadiennes et internationales sur le droit d’auteur. Tous les droits sur le contenu sont
      expressément réservés et aucun droit sur le contenu du site ne vous est accordé.
    </p>
    <p>
      <b>
        La plus récente modification apportée aux présentes conditions d’utilisation a été effectuée le 10 décembre
        2020.
      </b>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Policy'
}
</script>
