<template>
  <div class="admin__content admin__blog" style="">
    <div class="admin__blog--title">
      <h1>
        {{ getTitle }}
      </h1>
      <button v-if="!selected" class="button admin__blog--title-btn" @click="buttonAction">
        Créer un article
      </button>
    </div>
    <FirebaseBlogForm
      v-if="selected"
      :id="selected !== 'new' ? selected : null"
      :article="selected !== 'new' ? articles[selected] : null"
      :edit="selected !== 'new'"
      @close="selected = false"
    />
    <div v-else-if="articles" class="admin__blog--articles">
      <div v-for="(article, key) in articles" :key="key" class="blog__card--admin" @click="selected = key">
        <img
          :src="article.image_url ? article.image_url : 'https://i.stack.imgur.com/y9DpT.jpg'"
          class="blog__card--img"
        />
        <div class="blog__card--content">
          <p class="blog__card--title">
            {{ article.title }}
          </p>
          <p class="blog__card--text">
            {{ article.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirebaseBlogForm from '@/components/admin/FirebaseBlogForm.vue'

export default {
  name: 'FirebaseBlog',
  components: { FirebaseBlogForm },
  data() {
    return {
      selected: false,
      articles: null
    }
  },
  computed: {
    getTitle() {
      if (this.selected === 'new') {
        return "Création d'un nouvel article"
      } else if (this.selected) {
        return `Modification de l'article : ${this.articles[this.selected].title}`
      }
      return 'Gestion des articles'
    }
  },
  created() {
    this.$firebase.db.ref('/blog').on('value', data => {
      this.articles = Object.entries(data.val())
        .sort((a, b) => b[1].timestamp - a[1].timestamp)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
    })
  },
  methods: {
    buttonAction() {
      this.selected ? (this.selected = false) : (this.selected = 'new')
    }
  }
}
</script>
