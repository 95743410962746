<template>
  <div class="admin">
    <div class="admin__nav">
      <RouterLink to="/">
        <img src="/images/logo-gradient.svg" alt="PMPQ" class="navbar__logo" width="52" height="52" />
      </RouterLink>

      <div>
        <span class="admin__nav--item" :class="{ '-active': tab === 'text' }" @click="tab = 'text'">
          Gestion des textes
        </span>
        <span class="admin__nav--item" :class="{ '-active': tab === 'blog' }" @click="tab = 'blog'">
          Gestion du blogue
        </span>
      </div>
      <button class="button" style="height: 2rem" @click="logout">Déconnexion</button>
    </div>
    <FirebaseText v-if="tab === 'text'" />
    <FirebaseBlog v-if="tab === 'blog'" />
  </div>
</template>

<script>
import FirebaseText from '@/components/admin/FirebaseText.vue'
import FirebaseBlog from '@/components/admin/FirebaseBlog.vue'

export default {
  name: 'Admin',
  components: {
    FirebaseBlog,
    FirebaseText
  },
  data() {
    return {
      tab: 'text'
    }
  },
  methods: {
    logout() {
      this.$auth.logout()
    }
  }
}
</script>
