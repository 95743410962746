<template>
  <div>
    <h1>Statistiques</h1>
    <div v-for="(value, key) in stats" :key="key">
      <SimpleTextInput :name="key" :value="stats[key]" @input="inputChanged" />
    </div>
  </div>
</template>

<script>
import SimpleTextInput from '@/components/admin/inputs/SimpleTextInput.vue'

export default {
  name: 'Stats',
  components: { SimpleTextInput },
  data() {
    return {
      stats: {}
    }
  },
  mounted() {
    this.stats = { ...this.$content.stats }
  },
  methods: {
    inputChanged(event) {
      const { name: key, value } = event.target
      this.stats[key] = value
      this.$emit('firebaseTextChanged', { key: `stats.${key}`, value })
    }
  }
}
</script>
