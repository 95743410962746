<template>
  <label>
    <input class="admin__text-input" :value="value" :name="name" type="text" @input="inputChanged" />
  </label>
</template>

<script>
export default {
  name: 'SimpleTextInput',
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true }
  },
  data() {
    return {}
  },
  methods: {
    inputChanged(event) {
      this.$emit('input', event)
    }
  }
}
</script>

<style scoped></style>
