<template>
  <section id="references" class="section more-info">
    <h2 v-scroll:reveal="{ delay: 50 }" class="more-info__title">
      {{ $c('info.title') }}
    </h2>

    <div v-scroll:reveal="{ delay: 150 }" class="more-info__text">
      {{ $c('info.subtitle') }}
    </div>

    <div class="row -wrap -spread">
      <div
        v-for="(card, key) in cards"
        :key="`key-${key}`"
        v-scroll:reveal="{ delay: key * 75, offset: key > 2 ? 0 : null }"
        class="more-info__card"
      >
        <h3 class="more-info__card--title">
          {{ card.title }}
        </h3>

        <p class="more-info__card--text">
          {{ card.subtitle }}
        </p>

        <a class="more-info__card--button" :href="card.link" target="_blank" :title="card.title" rel="noopener">
          <Icon id="arrow" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MoreInfo',
  computed: {
    cards() {
      return this.$c('info.links')
    }
  }
}
</script>
