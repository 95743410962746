<template>
  <div class="section watermark">
    <div v-scroll:reveal="{ delay: 75, offset: 0 }" class="watermark__text">
      {{ $c('footer.pourmoi') }}
    </div>
    <div v-scroll:reveal="{ delay: 100, offset: 0 }" class="watermark__separator" />
    <div v-scroll:reveal="{ delay: 125, offset: 0 }" class="watermark__img" />
  </div>
</template>

<script>
export default {
  name: 'WaterMark'
}
</script>
