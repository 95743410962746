<template>
  <div style="text-align: center;">
    <div class="admin__row">
      <label>
        <p>Titre</p>
        <input v-model="form.title" class="admin__text-input -full" type="text" />
      </label>
    </div>

    <div class="admin__row">
      <label>
        <p>Description</p>
        <input v-model="form.description" class="admin__text-input -full" type="text" />
      </label>
    </div>

    <div class="admin__row">
      <label>
        <p>Id youtube ex : https://www.youtube.com/watch?v=<b style="font-weight: 700;">n8mlP6R4kbo</b></p>
        <input v-model="form.youtube" class="admin__text-input -full" type="text" />
      </label>
    </div>
    <div class="admin__row" style="text-align: left;">
      <label>
        <span>Thumbnail image : </span>
        <input type="file" @change="handleFile" />
      </label>
    </div>

    <div class="admin__row" style="text-align: left;">
      <label>
        <span>date : </span>
        <input v-model="form.date" type="date" />
      </label>
    </div>

    <div class="admin__row">
      <Editor v-model="form.html" :init="tinyConfig" api-key="x3itgo4udcluk82n12klzlf3tzmv872x1wcx2tcq3si9qkki" />
    </div>
    <div
      style="display: flex;
justify-content: space-between;"
    >
      <button v-if="id" class="button admin__content--button -delete" @click="deleteArticle">
        Supprimer
      </button>
      <div v-else></div>
      <button class="button admin__content--button" :disabled="loading" @click="save">
        {{ loading ? 'Chargement' : button }}
      </button>
      <button class="button admin__content--button -cancel" @click="$emit('close')">
        Annuler
      </button>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'FirebaseBlogForm',
  components: { Editor },
  props: {
    id: {
      type: String
    },
    article: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        youtube: '',
        file: null,
        html: '',
        date: new Date().toISOString().substr(0, 10)
      },
      tinyConfig: {
        height: 500,
        menubar: false,
        plugins: ['lists, link'],
        toolbar: 'undo redo | h1 h2 h3 | bold italic | bullist numlist | link'
      },
      button: 'Sauvegarder',
      loading: false
    }
  },
  mounted() {
    if (this.edit) {
      Object.assign(this.form, this.article)
      this.form.date = new Date(this.form.date).toISOString().substr(0, 10)
    }
  },
  methods: {
    handleFile(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const [firstFile] = files
      this.form.file = firstFile
    },
    save() {
      this.loading = true
      if (this.form.file) {
        this.uploadFile(this.form.file).then(url => {
          this.form.image_url = url
          this.setData()
        })
      } else {
        this.setData()
      }
    },
    setData() {
      let uid
      if (this.edit) {
        uid = this.id
      } else {
        uid = uuidv4()
      }
      this.$firebase.db
        .ref(`/blog/${uid}`)
        .set({
          title: this.form.title,
          description: this.form.description,
          slug: this.sanitizeUrl(this.form.title),
          youtube: this.form.youtube,
          image_url: this.form.image_url
            ? this.form.image_url
            : 'https://firebasestorage.googleapis.com/v0/b/ciusss-f6061.appspot.com/o/blog%2Fnoimg.jpg?alt=media&token=1db4cde8-0e94-4181-8535-a19bdbce40df',
          html: this.form.html,
          timestamp: this.form.timestamp ? this.form.timestamp : Date.now(),
          date: new Date(this.form.date).getTime()
        })
        .then(() => {
          this.button = 'Changements sauvegardés!'
          setTimeout(() => {
            this.button = 'Sauvegarder'
            this.loading = false
            this.$emit('close')
          }, 1000)
        })
    },
    sanitizeUrl(str) {
      return str
        .normalize('NFD')
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[-]+/g, '-')
        .replace(/[^\w-]+/g, '')
    },
    uploadFile(file) {
      const { storage } = this.$firebase
      let storageRef

      return new Promise((resolve, reject) => {
        storageRef = storage.ref(`blog/${file.name}`)
        storageRef
          .put(file)
          .then(({ ref }) => {
            ref
              .getDownloadURL()
              .then(url => {
                resolve(url)
              })
              .catch(err => {
                reject(err)
              })
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteArticle() {
      if (this.id) {
        this.$firebase.db.ref(`/blog/${this.id}`).remove()
        this.$emit('close')
      }
    }
  }
}
</script>
