<template>
  <div>
    <h1>Video</h1>
    <div v-for="(value, key) in video" :key="key">
      <SimpleTextInput :name="key" :value="video[key]" @input="inputChanged" />
    </div>
  </div>
</template>

<script>
import SimpleTextInput from '@/components/admin/inputs/SimpleTextInput.vue'

export default {
  name: 'Video',
  components: { SimpleTextInput },
  data() {
    return {
      video: {}
    }
  },
  mounted() {
    this.video = { ...this.$content.video }
  },
  methods: {
    inputChanged(event) {
      const { name: key, value } = event.target
      this.video[key] = value
      this.$emit('firebaseTextChanged', { key: `video.${key}`, value })
    }
  }
}
</script>
