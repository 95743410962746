<template>
  <h1 class="header__title row__item">
    Pour Moi
    <br />

    <transition name="banner" appear>
      <span class="banner">Pour</span>
    </transition>
    &nbsp;
    <transition name="banner" mode="out-in" appear>
      <span :key="currentWord" class="banner">{{ currentWord }}</span>
    </transition>
  </h1>
</template>

<script>
export default {
  name: 'HeaderTitle',
  data() {
    return {
      intervalInstance: null,
      currentWord: null,
      currentIndex: 0,
      hasInitialWords: true,
      initialWords: ['Québec', 'Charlevoix', 'Beaupré', 'Mon île', 'La Jacques-Cartier', 'Portneuf'],
      wordList: ['Mes Amis', 'Mon Équipe', 'Mon Cinéma', 'Mes Étudiants', 'Mon Sport', 'Mes Parents', 'Mes Artistes']
    }
  },
  created() {
    if (this.intervalInstance) return

    this.setNextWord()
    this.intervalInstance = setInterval(this.setNextWord, 1700)
  },
  beforeDestroy() {
    clearInterval(this.intervalInstance)
  },
  methods: {
    setNextWord() {
      this.hasInitialWords = this.initialWords.length > 0
      if (this.hasInitialWords) this.nextInitialWord()
      else this.iterateWordList()
    },
    nextInitialWord() {
      this.currentWord = this.initialWords.shift()
      this.wordList.push(this.currentWord)
    },
    iterateWordList() {
      this.currentWord = this.wordList[this.currentIndex]
      this.currentIndex = (this.currentIndex + 1) % this.wordList.length
    }
  }
}
</script>
