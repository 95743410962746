<template>
  <div>
    <h1>Pied de page</h1>
    <div v-for="(value, key) in footer" :key="key">
      <SimpleTextInput :name="key" :value="footer[key]" @input="inputChanged" />
    </div>
  </div>
</template>

<script>
import SimpleTextInput from '@/components/admin/inputs/SimpleTextInput.vue'

export default {
  name: 'Footer',
  components: { SimpleTextInput },
  data() {
    return {
      footer: {}
    }
  },
  mounted() {
    this.footer = { ...this.$content.footer }
  },
  methods: {
    inputChanged(event) {
      const { name: key, value } = event.target
      this.footer[key] = value
      this.$emit('firebaseTextChanged', { key: `footer.${key}`, value })
    }
  }
}
</script>
