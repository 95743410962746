<template>
  <div v-if="articles.length > 0" class="header-blog">
    <template v-if="$device.tablet">
      <router-link :to="{ name: 'article', params: { slug: articles[0].slug } }" class="header-blog__card -big">
        <div class="header-blog__card--img -big" :style="`background-image: url(${articles[0].image_url})`" />
        <div class="header-blog__card--title">
          {{ articles[0].title }}
        </div>
        <div v-if="$device.laptop" class="header-blog__card--txt">
          {{ articles[0].description }}
        </div>
      </router-link>

      <div class="header-blog__col">
        <router-link
          v-if="articles[1]"
          class="header-blog__card"
          :to="{ name: 'article', params: { slug: articles[1].slug } }"
        >
          <div class="header-blog__card--img" :style="`background-image: url(${articles[1].image_url})`" />
          <div class="header-blog__card--title">
            {{ articles[1].title }}
          </div>
        </router-link>

        <router-link
          v-if="articles[2]"
          class="header-blog__card"
          :to="{ name: 'article', params: { slug: articles[2].slug } }"
        >
          <div class="header-blog__card--img" :style="`background-image: url(${articles[2].image_url})`" />
          <div class="header-blog__card--title">
            {{ articles[2].title }}
          </div>
        </router-link>
      </div>

      <div class="header-blog__col">
        <router-link
          v-if="articles[3]"
          class="header-blog__card"
          :to="{ name: 'article', params: { slug: articles[3].slug } }"
        >
          <div class="header-blog__card--img" :style="`background-image: url(${articles[3].image_url})`" />
          <div class="header-blog__card--title">
            {{ articles[3].title }}
          </div>
        </router-link>

        <router-link
          v-if="articles[4]"
          :to="{ name: 'article', params: { slug: articles[4].slug } }"
          class="header-blog__card"
        >
          <div class="header-blog__card--img" :style="`background-image: url(${articles[4].image_url})`" />
          <div class="header-blog__card--title">
            {{ articles[4].title }}
          </div>
        </router-link>
      </div>
    </template>
    <template v-else>
      <router-link
        v-for="article in articles"
        :key="article.title"
        :to="{ name: 'article', params: { slug: article.slug } }"
        class="header-blog__card -scroll"
      >
        <div class="header-blog__card--img" :style="`background-image: url(${article.image_url})`" />
        <div class="header-blog__card--title">
          {{ article.title }}
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HeaderBlog',
  data() {
    return {
      articles: []
    }
  },
  created() {
    this.$firebase.db.ref('/blog').on('value', data => {
      this.articles = Object.values(data.val()).sort((a, b) => b.date - a.date)
    })
  }
}
</script>
