<template>
  <div id="page-home">
    <SiteHeader />
    <FormSection />
    <VideoSection />
    <StatsSection />
    <Curator />
    <MoreInfo />
    <WaterMark />
  </div>
</template>

<script>
import { SiteHeader, MoreInfo, StatsSection, Curator, VideoSection, WaterMark, FormSection } from '@/components'

export default {
  name: 'Home',
  components: { FormSection, SiteHeader, MoreInfo, StatsSection, Curator, VideoSection, WaterMark },
  metaInfo: {
    meta: [
      {
        name: 'description',
        content:
          "La Santé publique de la Capitale-Nationale vous informe sur la COVID-19. Elle vous inspire afin que vos efforts pour limiter la transmission aient plus de sens."
      },
      { property: 'og:title', content: 'Pour moi, Pour Québec' },
      {
        property: 'og:description',
        content:
          "La Santé publique de la Capitale-Nationale vous informe sur la COVID-19. Elle vous inspire afin que vos efforts pour limiter la transmission aient plus de sens."
      },
      { property: 'og:image', content: '/images/PMPQ-social.jpg' }
    ]
  }
}
</script>
