import { firebase } from '@firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import Vue from 'vue'
import router from '@/router'

const firebaseConfig = {
  apiKey: 'AIzaSyBCfcK5p_wOezR5ONui40hGt95iFVq8Tm4',
  authDomain: 'ciusss-f6061.firebaseapp.com',
  databaseURL: 'https://ciusss-f6061.firebaseio.com',
  projectId: 'ciusss-f6061',
  storageBucket: 'ciusss-f6061.appspot.com',
  messagingSenderId: '565293120808',
  appId: '1:565293120808:web:e821bd9d355ab7dd3da41d',
  measurementId: 'G-Y7Z7BKH10H'
}
firebase.initializeApp(firebaseConfig)

const db = firebase.database()
const auth = firebase.auth()
const storage = firebase.storage()

Vue.prototype.$firebase = {
  db,
  auth,
  storage
}

const authService = {
  initialized: false,
  user: null,

  initialize() {
    return new Promise(resolve => {
      if (this.initialized) {
        resolve(this.user)
      }
      // this adds a hook for the initial auth-change event
      auth.onAuthStateChanged(user => {
        this.initialized = true
        this.user = user
        resolve(user)
      })
    })
  },

  authenticated() {
    return this.initialize().then(user => {
      return user && !user.isAnonymous
    })
  },

  setUser(user) {
    this.user = user
  },

  login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  },

  logout() {
    auth.signOut().then(() => {
      router.push({ name: 'admin-login' })
    })
  }
}

Vue.prototype.$auth = authService
