<template>
  <footer class="footer">
    <span>
      {{ new Date().getFullYear() }} {{ $c('footer.ciussscn') }}
      <a href="https://boitebeet.com" class="footer__link" target="_blank" rel="noopener">Beet</a>
    </span>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>
