<template>
  <section>
    <div v-if="!sent" class="form">
      <div class="form__title">
        {{ $c('header.form.title') }}
      </div>
      <div class="form__box">
        <label class="checkbox -box">
          <input v-model="topbox" type="checkbox" />
          <span class="checkbox__checkmark -box" />
          {{ $c('header.form.subtitle') }}
        </label>
      </div>
    </div>

    <div v-if="sent && open" class="form">
      <div class="form__section">
        <div class="form__title">
          {{ $c('header.form.share') }}
        </div>
        <div class="form__box">
          {{ $c('header.form.join') }}
        </div>
        <p class="form__text">
          {{ $c('header.form.engaged') }}
        </p>
        <div v-if="!navigator.share" class="form__share">
          <template v-for="(args, key) in sharePlatforms">
            <div v-if="Array.isArray(args)" :key="`share-${key}`" class="form__share--button" @click="share(args)">
              <Icon :id="key" />
            </div>
            <a v-else :key="`share-${key}`" class="form__share--button" href="mailto:foo@foo.com">
              <Icon :id="key" />
            </a>
          </template>
        </div>
        <div v-else>
          <div class="form__share--mobilebutton" @click="mobileShare()">
            <icon id="infinity" />
            <span>Partager mon engagement</span>
          </div>
        </div>
      </div>

      <div class="form__footer">
        {{ $c('header.form.thanks') }}
      </div>
    </div>

    <div v-else class="form">
      <div v-if="!open && $device.laptop" class="form__grad"></div>

      <simplebar class="form__scroll">
        <div class="row -spread -align-center -mobile ">
          <div
            v-for="(val, key) in form"
            :key="`tab-${key}`"
            class="form__tab"
            :class="{ '-disabled': !open }"
            @click="activeTab = key"
          >
            <Icon :id="val.icon" class="form__tab--icon" :class="{ '-active': open && key === activeTab }" />
            <span class="form__tab--title">{{ val.title }}</span>
            <div class="form__tab--active" :class="{ '-active': key === activeTab && open }" />
          </div>
        </div>
      </simplebar>

      <template v-if="open">
        <div class="form__subtitle">
          {{ form[activeTab].subtitle }}
        </div>
        <div class="form__inputs">
          <div v-for="(val, key) in form[activeTab].questions" :key="`question-${key}`" class="input">
            <label class="checkbox">
              <input v-model="formData[activeTab][key]" type="checkbox" />
              <span class="checkbox__checkmark" />
              {{ val }}
            </label>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import simplebar from 'simplebar-vue'

export default {
  name: 'HeaderForm',
  components: { simplebar },
  props: {
    open: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      topbox: true,
      existing: false,
      sent: false,
      activeTab: 'work',
      form: {
        work: {
          title: "Au travail ou à l'école",
          subtitle:
            'Je m’engage à adapter mes comportements pour contribuer à un environnement sain et agréable. Je choisis de :',
          icon: 'bag',
          questions: [
            'Garder mes distances et mon masque (même pendant les périodes de pause et de repas)',
            'M’informer sur la bonne façon de porter le masque et de le manipuler (même si je pense bien le porter)',
            'Prioriser le télétravail ou autoriser mes employés à en faire lorsque c’est possible',
            'Faire des rencontres virtuelles informelles avec mes collègues ou mes amis pour prendre des nouvelles',
            'Nettoyer régulièrement mes outils de travail et éviter de les partager'
          ]
        },
        relative: {
          title: 'Avec mes proches et dans les commerces',
          subtitle: 'Je m’engage à réinventer ma façon d’entrer en contact avec mon entourage. Je choisis de :',
          icon: 'person',
          questions: [
            'Inviter mon entourage à adopter des habitudes sécuritaires',
            'Appeler un proche qui vit seul pour prendre de ses nouvelles',
            'M’assurer de garder une distance de 2 mètres avec les autres personnes même si nous portons un masque',
            'Me laver les mains à l’entrée des commerces même si personne ne me surveille',
            'Faire mes achats en ligne ou choisir les moments peu fréquentés dans les commerces',
            'Planifier des soupers virtuels et des soirées de jeux à distance (bingo, devine le dessin, mimes, etc.)'
          ]
        },
        home: {
          title: 'Dans mon domicile',
          subtitle:
            'Je m’engage à porter une attention particulière à tout ce qui peut limiter la transmission du virus. Je choisis de :',
          icon: 'home',
          questions: [
            'Rester chez moi lorsque j’ai des symptômes',
            'Encourager les restaurateurs locaux en me faisant livrer des plats que j’aime',
            'Prévoir mon menu de la semaine afin de limiter mes visites à l’épicerie',
            'Me laver les mains dès le retour chez moi',
            'Décrocher des médias sociaux pour limiter la surcharge d’information'
          ]
        },
        commerce: {
          title: 'Pour mon bien-être',
          subtitle:
            'Je m’engage à choisir des activités qui m’aident à garder un moral positif dans le contexte. Je choisis de :',
          icon: 'rocks',
          questions: [
            'Organiser des soirées de cinéma maison en famille',
            'Découvrir des parcs de ma municipalité',
            'Marcher ou courir à l’extérieur le plus souvent possible',
            'Faire des tâches que je repousse depuis longtemps',
            'Ne rien faire, si cela me fait du bien',
            'Parler avec un proche si je me sens anxieux ou stressé',
            'Demander de l’aide professionnelle si j’ai de la difficulté à fonctionner'
          ]
        }
      },
      formData: {
        work: [false, false, false, false, false, false],
        relative: [false, false, false, false, false, false],
        home: [false, false, false, false, false],
        commerce: [false, false, false, false, false, false, false]
      },
      sharePlatforms: {
        facebook: [
          `https://www.facebook.com/dialog/share?app_id=381239146397050&display=popup&href=${window.location.href}&redirect_uri=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        twitter: [
          `https://twitter.com/intent/tweet?hashtags=PourMoiPourQuebec&text=${"Aujourd'hui, Pour moi, Pour ma région, je me suis engagé à maintenir mes efforts quotidiens pour diminuer la transmission du virus. Faites-en autant, ou même plus, pour favoriser un retour à une vie plus normale."}&url=${
            window.location.href
          }`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        linkedin: [
          `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        messenger: [
          `fb-messenger://share/?link=${window.location.href}&app_id=381239146397050`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        email:
          'mailto:?subject=Pour%20moi%2C%20pour%20Qu%C3%A9bec%0A&amp;body=%3Ca%20href%3D%22https%3A%2F%2Fbeet-pmpq-staging.netlify.app%2F%22%3EPour%20moi%2C%20pour%20Qu%C3%A9bec%3C%2Fa%3E'
      }
    }
  },
  computed: {
    uuid() {
      return localStorage.getItem('uuid')
    },
    navigator() {
      return navigator
    }
  },
  watch: {
    topbox(to) {
      this.$emit('topbox', to)
    },
    open(to) {
      this.topbox = to
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$events.on('formSubmit', this.saveData)
  },

  methods: {
    share(args) {
      window.open(...args)
      this.addShareData()
    },
    getData() {
      this.$firebase.db.ref(`/engagements/${this.uuid}`).once('value', snap => {
        if (snap.val()) {
          Object.assign(this.formData, snap.val())
          this.existing = true
        }
      })
    },
    addShareData() {
      this.$firebase.db.ref(`/share/count`).once('value', snap => {
        const val = Number(snap.val()) + 1
        this.$firebase.db.ref(`/share/count`).set(val)
      })
    },
    saveData() {
      if (!this.existing) {
        this.$firebase.db.ref(`/engagements/count`).once('value', snap => {
          const val = Number(snap.val()) + 1
          this.$firebase.db.ref(`/engagements/count`).set(val)
        })
      }
      this.$firebase.db.ref(`/engagements/${this.uuid}`).set(this.formData)
      this.sent = true
    },
    mobileShare() {
      if (navigator.share) {
        navigator
          .share({
            title: 'Pour moi, pour Québec',
            text:
              "Aujourd'hui, Pour moi, Pour ma région, je me suis engagé à maintenir mes efforts quotidiens pour diminuer la transmission du virus. Faites-en autant, ou même plus, afin que notre région revienne en zone verte.",
            url: window.location.href
          })
          .then(() => {
            this.addShareData()
          })
          .catch(console.error)
      }
    }
  }
}
</script>
