<template>
  <section id="stats" class="stats">
    <template v-if="inspq">
      <h2 v-scroll:reveal="{ delay: 50 }" class="stats__title">
        {{ $c('stats.title') }}
      </h2>

      <p v-scroll:reveal="{ delay: 100 }" class="stats__text">
        {{ $c('stats.subtitle') }}
      </p>

      <div v-scroll:reveal="{ delay: 150 }" class="stats__average">
        <h4 class="stats__average--title">Capitale-Nationale</h4>

        <template v-if="$device.laptop">
          <p class="stats__average--data">+ {{ inspq.average }}</p>

          <div class="stats__average--time">
            <p>cas confirmés dans les 7 derniers jours</p>
            <p class="stats__average--small">Donnée mise à jour<br />le {{ getTimestamp() }} à 11:30</p>
          </div>
        </template>

        <template v-else>
          <div class="stats__average--mobilesection">
            <div class="stats__average--data -mobile">+{{ inspq.average }}</div>
            <div>cas confirmés dans les 7 derniers jours</div>
          </div>

          <span class="stats__average--small">Donnée mise à jour<br />le {{ getTimestamp() }} à 11:30 </span>
        </template>
      </div>

      <div v-if="currentRatio" class="stats__cards">
        <div
          v-for="(value, key) in currentRatio"
          :key="`card-${key}`"
          v-scroll:reveal="{ delay: delay[key] }"
          class="stats__card"
        >
          <p class="stats__card--title">{{ key }}</p>

          <div class="stats__card--data">
            <span>{{ value.ratio }}</span>

            <div class="stats__card--change" :class="{ '-up': Number(value.change) > 0 }">
              <Icon
                v-if="Number(value.change) !== 0"
                id="arrow"
                class="stats__card--arrow"
                :class="{ '-up': value.change > 0, '-down': value.change < 0 }"
              />
              {{
                value.change > 0
                  ? `+${value.change}`
                  : `-${
                      Number(value.change) === 0
                        ? Number(value.change).toFixed(2)
                        : value.change.substring(1, value.change.length)
                    }`
              }}
              %
            </div>
          </div>

          <div class="stats__card--text">
            <p>cas actifs pour</p>
            <p>100 000 habitants</p>
          </div>

          <p class="stats__card--time">Donnée mise à jour<br />le {{ getTimestamp() }} à 16:30</p>
        </div>
      </div>

      <span v-scroll:reveal="{ delay: 75 }" class="stats__text">
        {{ $c('stats.more') }}
      </span>

      <button v-scroll:reveal="{ delay: 100 }" class="stats__button" name="Consultez le site de l’INSPQ">
        <Icon id="arrow" />

        <a
          class="stats__button--text"
          href="https://www.inspq.qc.ca/"
          title="Site de l'INSPQ"
          aria-label="Site de l'INSPQ"
          target="_blank"
          rel="noopener"
        >
          {{ $c('stats.button') }}
        </a>
      </button>
    </template>
  </section>
</template>

<script>
export default {
  name: 'StatsSection',
  data() {
    return {
      inspq: null,
      currentRatio: null,
      delay: {
        'Québec (nord)': 200,
        'Québec (sud)': 350,
        charlevoix: 500,
        portneuf: 650
      }
    }
  },
  watch: {
    inspq(to) {
      this.currentRatio = {}
      this.currentRatio['Québec (nord)'] = {
        ratio: to.today['Québec-Nord'].currentRatio,
        change: this.getRatio(to.today['Québec-Nord'].currentRatio, to.yesterday['Québec-Nord'].currentRatio)
      }
      this.currentRatio['Québec (sud)'] = {
        ratio: to.today['Québec-Sud'].currentRatio,
        change: this.getRatio(to.today['Québec-Sud'].currentRatio, to.yesterday['Québec-Sud'].currentRatio)
      }
      this.currentRatio.charlevoix = {
        ratio: to.today.Charlevoix.currentRatio,
        change: this.getRatio(to.today.Charlevoix.currentRatio, to.yesterday.Charlevoix.currentRatio)
      }
      this.currentRatio.portneuf = {
        ratio: to.today.Portneuf.currentRatio,
        change: this.getRatio(to.today.Portneuf.currentRatio, to.yesterday.Portneuf.currentRatio)
      }
    }
  },
  created() {
    this.$firebase.db.ref('inspq').on('value', snap => {
      this.inspq = snap.val()
    })
  },
  methods: {
    getTimestamp() {
      const date = new Date()
      if (date.getHours() < 17) {
        date.setDate(date.getDate() - 1)
      }
      return `${date.toLocaleString('fr', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })}`
    },
    getRatio(a, b) {
      if (b !== 0) {
        const result = (a / b) * 100 - 100
        return result.toFixed(2)
      }
      return 0
    }
  }
}
</script>
