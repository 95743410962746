import _get from 'lodash.get'

export default Vue => {
  const ContentVM = new Vue({
    name: 'ContentVM',
    data() {
      return {
        textData: {}
      }
    },
    created() {
      this.loadContent().then(data => {
        this.textData = data
      })
    },
    methods: {
      loadContent() {
        return new Promise(resolve => {
          this.$firebase.db.ref('text').once('value', snap => {
            resolve(snap.val())
          })
        })
      }
    }
  })

  Vue.mixin({
    computed: {
      $content() {
        return ContentVM.textData
      }
    },
    methods: {
      $c(key, fallback = '') {
        return _get(ContentVM.textData, key, fallback)
      }
    }
  })
}
