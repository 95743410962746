const defaultErrorHandler = err => console.error(err)

export default (Vue, apiKey) => {
  const apiPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/api.js'
    script.addEventListener('error', reject)

    script.addEventListener('load', () => {
      const { gapi } = window
      if (!gapi) return reject(Error('Failed to load gapi'))

      gapi.load('client', () => {
        gapi.client.setApiKey(apiKey)

        gapi.client
          .load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest')
          .then(() => {
            if (!gapi.client.youtube) return reject(Error('Failed to find YouTube client in window'))
            resolve(gapi.client.youtube)
          })
          .catch(reject)
      })
    })

    document.head.appendChild(script)
  })

  Vue.prototype.$getYouTubeApi = (callback, onError = defaultErrorHandler) => apiPromise.then(callback).catch(onError)
}
