import './styles'
// eslint-disable-next-line import/no-unassigned-import
import 'babel-polyfill'
import './startup'
// eslint-disable-next-line import/no-unassigned-import
import './extensions/firebase'
import Axios from 'axios'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import DeviceQueries from 'vue-device-queries'
import VueAnalytics from 'vue-analytics'

import router from './router'
import App from './App.vue'
import GlobalComponents from './components/global'
import ScrollUtils from './extensions/ScrollUtils'
import ScrollHub from './extensions/scrollHub'
import GlobalEvents from './extensions/GlobalEvents'
import YouTubeApiContext from './extensions/YouTubeApiContext'
import Content from './extensions/content'
import { toRem } from './helpers'

const offset = window.innerHeight * 0.15
const scrollingElement = document.scrollingElement || document.documentElement

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(GlobalComponents)
Vue.use(GlobalEvents)
Vue.use(Content)
Vue.use(ScrollUtils, { scrollEventTarget: window })
Vue.use(ScrollHub, { scrollingElement, scrollEventTarget: window, reveal: { offset } })
Vue.use(YouTubeApiContext, 'AIzaSyDMUsQtCbRKGNtyt0ihbqej-k16kYi7_VU')
Vue.use(VueAnalytics, { id: 'UA-185364070-1', router })
Vue.prototype.$http = Axios

Vue.use(DeviceQueries, {
  phone: `max-width: ${toRem(567)}`,
  tablet: `min-width: ${toRem(568)}`,
  mobile: `max-width: ${toRem(1024)}`,
  laptop: `min-width: ${toRem(1025)}`,
  desktop: `min-width: ${toRem(1360)}`
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
