import pace from 'pace-progress'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

pace.start()

pace.once('hide', () => {
  window.prerenderReady = true
})

axios('/icons.svg').then(({ data }) => document.body.insertAdjacentHTML('afterbegin', data))

try {
  window.localStorage.setItem('test', 1)
  window.localStorage.getItem('test')
  window.localStorage.removeItem('test')
} catch (e) {
  window.localStorage = {
    setItem: () => {},
    getItem: () => {},
    removeItem: () => {}
  }
}

if (!localStorage.getItem('uuid')) {
  localStorage.setItem('uuid', uuidv4())
}
