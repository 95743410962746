<template>
  <div>
    <h1>Information</h1>
    <div v-for="(value, key) in info" :key="key">
      <SimpleTextInput v-if="key !== 'links'" :name="key" :value="info[key]" @input="inputChanged" />
    </div>
    <div class="admin__content--links">
      <h1>Liens</h1>
      <div v-for="(value, link) in info.links" :key="link">
        <h3 class="admin__content--subtitle">{{ value.title }}</h3>
        <div v-for="(v, key) in value" :key="key">
          <SimpleTextInput :name="`links.${link}.${key}`" :value="info.links[link][key]" @input="inputChanged" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleTextInput from '@/components/admin/inputs/SimpleTextInput.vue'

export default {
  name: 'Footer',
  components: { SimpleTextInput },
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    this.info = { ...this.$content.info }
  },
  methods: {
    inputChanged(event) {
      const { name: key, value } = event.target
      this.info[key] = value
      this.$emit('firebaseTextChanged', { key: `info.${key}`, value })
    }
  }
}
</script>
