<template>
  <section id="testimonials" class="videos section">
    <header class="videos__top">
      <h2 v-scroll:reveal="{ delay: 50 }" class="videos__top--title">
        {{ $c('video.title') }}
      </h2>

      <p v-scroll:reveal="{ delay: 150 }" class="videos__top--text">
        {{ $c('video.subtitle') }}
      </p>
    </header>

    <div v-if="videos.length" class="row -wrap -spread">
      <div v-for="video in videos" :key="video.id" class="videos__frame">
        <div v-if="video.loading" class="videos__viewbox" />

        <iframe
          v-else
          :src="`${video.url}${video.id}${getQueryString(video.options)}`"
          class="videos__viewbox"
          title="Témoignage vidéo"
          frameborder="0"
          allowfullscreen
        />
      </div>
    </div>
    <button
      v-if="standbyVideos.length"
      class="button curator__button"
      name="Voir plus de publication"
      @click="addFourVideos"
    >
      <Icon id="plus" />
      {{ $c('video.button') }}
    </button>
  </section>
</template>

<script>
export default {
  name: 'VideoSection',
  data() {
    return {
      videos: [],
      standbyVideos: [],
      playlist: 'PLHzdV56NlAzR_NOrHcO4IYBRbl9lSLeh0'
    }
  },
  created() {
    this.videos = Array.from(Array(4), () => ({ loading: true }))
    this.addPlaylist(this.playlist).then(this.addFourVideos)
  },
  methods: {
    addFourVideos() {
      const [{ loading }] = this.videos
      const nextVideos = this.standbyVideos.splice(0, 4)
      this.videos = loading ? [...nextVideos] : [...this.videos, ...nextVideos]
    },
    addPlaylist(playlistId) {
      return this.getPlaylist(playlistId).then(list => this.standbyVideos.push(...list))
    },
    getPlaylist(playlistId) {
      const options = { controls: 0, modestbranding: 1 }

      return this.$getYouTubeApi(yt =>
        yt.playlistItems
          .list({
            playlistId,
            maxResults: 50,
            part: ['contentDetails']
          })
          .then(({ result }) =>
            result.items.map(({ contentDetails }) => ({
              options,
              id: contentDetails.videoId,
              url: 'https://www.youtube.com/embed/'
            }))
          )
      )
    },
    getQueryString(options) {
      return !options
        ? ''
        : `?${Object.entries(options)
            .map(keyValue => keyValue.join('='))
            .join('&')}`
    }
  }
}
</script>
