import Vue from 'vue'
import VueRouter from 'vue-router'

import { Home, NotFound, Policy, Admin, Login, Blog, BlogArticle } from '@/views'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    component: Home
  },
  {
    path: '/blogue',
    name: 'blog',
    component: Blog
  },
  {
    path: '/blogue/:slug',
    name: 'article',
    component: BlogArticle
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    component: Login
  },
  {
    path: '*',
    name: 'catch-all',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '-active',
  scrollBehavior: (to, from, savedPosition) => {
    window.savedScroll = savedPosition && savedPosition.y
    return null
  },
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    Vue.prototype.$auth.authenticated().then(authenticated => {
      if (!authenticated) {
        const loginPath = window.location.pathname
        return next({ name: 'admin-login', query: { from: loginPath } })
      }
      return next()
    })
  }
  return next()
})

export default router
