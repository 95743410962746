<template>
  <div id="blog" class="blog">
    <div class="blog__header -justify-center -small">
      <h1 class="blog__header--title">
        Conseils du jour
      </h1>
      <p class="blog__header--text"></p>
    </div>
    <div v-if="Object.keys(articles).length > 0" class="blog__content">
      <router-link :to="{ name: 'article', params: { slug: lastArticle.slug } }" class="blog__card">
        <img :src="lastArticle.image_url" class="blog__card--img" />
        <div class="blog__card--content -nopad">
          <p class="blog__card--title">
            {{ lastArticle.title }}
          </p>
          <p class="blog__card--date">{{ getDate(lastArticle.date) }}</p>
          <p class="blog__card--text">
            {{ lastArticle.description }}
          </p>
        </div>
      </router-link>
      <router-link
        v-for="(article, key) in articles"
        :key="key"
        class="blog__card"
        :to="{ name: 'article', params: { slug: article.slug } }"
      >
        <img :src="article.image_url" class="blog__card--img -small" />
        <div class="blog__card--content">
          <p class="blog__card--title -nomargin">
            {{ article.title }}
          </p>
          <p class="blog__card--date">{{ getDate(article.date) }}</p>
          <p class="blog__card--text">
            {{ getText(article) }}
          </p>
        </div>
      </router-link>
    </div>
    <WaterMark />
  </div>
</template>

<script>
import { WaterMark } from '@/components'

export default {
  name: 'Blog',
  components: { WaterMark },
  data() {
    return {
      lastArticle: {},
      articles: []
    }
  },
  created() {
    this.$firebase.db.ref('/blog').on('value', data => {
      this.articles = Object.values(data.val()).sort((a, b) => b.date - a.date)
      const [lastArticle] = this.articles.splice(0, 1)
      this.lastArticle = lastArticle
    })
  },
  methods: {
    getText(article) {
      const text = article.description
      if (article.title.length > 40 && !this.$device.tablet) {
        if (text.length > 60) {
          return `${text.substr(0, 57)}...`
        }
      }
      if (text.length > 90 && !this.$device.tablet) {
        return `${text.substr(0, 87)}...`
      }

      return text
    },
    getDate(date) {
      const result = new Date(date + 24 * 60 * 60 * 1000)
      return `${result.toLocaleString('fr', {
        weekday: 'long',
        day: 'numeric',
        month: 'long'
      })}`
    }
  }
}
</script>
