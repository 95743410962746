<template>
  <header class="header">
    <HeaderGradient />

    <div class="header__content">
      <div class="row -align-top">
        <img src="/images/logo-hash.svg" width="52" height="52" alt="PMPQ" class="header__logo" />
        <BannerAnimation />
      </div>

      <div class="header__content--txt">
        {{ $c('header.blog') }}
      </div>

      <div class="header__content--flex">
        <div class="header__content--subtitle">
          Derniers conseils du jour
        </div>
        <router-link
          v-if="$device.laptop"
          :to="{ name: 'blog' }"
          class="header__content--button"
          name="Consultez tous les conseils"
        >
          <Icon id="arrow" class="header__content--button-icon" />
          Consultez tous les conseils
        </router-link>
      </div>

      <HeaderBlog />

      <router-link
        v-if="!$device.laptop"
        :to="{ name: 'blog' }"
        class="header__content--button"
        name="Consultez tous les conseils"
      >
        <Icon id="arrow" class="header__content--button-icon" />
        Consultez tous les conseils
      </router-link>
    </div>

    <button class="header__lead" aria-label="Défiler vers le bas" @click="onClickLead" />
  </header>
</template>

<script>
import { HeaderGradient, BannerAnimation, HeaderBlog } from './partials'

export default {
  name: 'SiteHeader',
  components: { HeaderBlog, HeaderGradient, BannerAnimation },
  data() {
    return {}
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$events.on('openForm', () => (this.open = true))
  },
  methods: {
    onClickLead() {
      this.$scroll.to(window.innerHeight, 0, 250)
    },
    getData() {}
  }
}
</script>
