<template>
  <div v-if="Object.keys($content).length" class="admin__content">
    <Header class="admin__content--section" @firebaseTextChanged="inputChanged" />
    <Info class="admin__content--section" @firebaseTextChanged="inputChanged" />
    <Stats class="admin__content--section" @firebaseTextChanged="inputChanged" />
    <Curator class="admin__content--section" @firebaseTextChanged="inputChanged" />
    <Video class="admin__content--section" @firebaseTextChanged="inputChanged" />
    <Footer class="admin__content--section" @firebaseTextChanged="inputChanged" />

    <button class="button admin__content--button" @click="saveChanges">
      {{ button }}
    </button>
  </div>
</template>

<script>
import Curator from '@/components/admin/sections/Curator.vue'
import Footer from '@/components/admin/sections/Footer.vue'
import Header from '@/components/admin/sections/Header.vue'
import Info from '@/components/admin/sections/Info.vue'
import Stats from '@/components/admin/sections/Stats.vue'
import Video from '@/components/admin/sections/Video.vue'

export default {
  name: 'FirebaseText',
  components: { Curator, Footer, Header, Info, Stats, Video },
  data() {
    return {
      changes: {},
      button: 'Sauvegarder'
    }
  },
  methods: {
    inputChanged({ key, value }) {
      this.changes[`text.${key}`] = value
    },
    saveChanges() {
      const updates = Object.keys(this.changes).reduce((a, k) => {
        a[k.replaceAll('.', '/')] = this.changes[k]
        return a
      }, {})
      this.$firebase.db
        .ref()
        .update(updates)
        .then(() => {
          this.changes = {}
          this.button = 'Changements sauvegardés!'

          setTimeout(() => {
            this.button = 'Sauvegarder'
          }, 1000)
        })
    }
  }
}
</script>
