<template>
  <div class="admin">
    <div class="admin__auth">
      Connexion administration
      <label>
        <div class="admin__auth--label">Email</div>
        <input v-model="email" type="text" class="admin__text-input -auth" />
      </label>
      <label>
        <div class="admin__auth--label">Password</div>
        <input v-model="password" type="password" class="admin__text-input -auth" />
      </label>
      <button class="button" @click="login()">Connection</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: ''
    }
  },
  mounted() {
    this.$auth.authenticated().then(authenticated => {
      if (authenticated) {
        this.$router.push({ name: 'admin' })
      }
    })
  },
  methods: {
    login() {
      this.$auth
        .login(this.email, this.password)
        .then(() => {
          if (this.$route.query.from) {
            this.$router.push(this.$route.query.from)
          } else {
            this.$router.push({ name: 'admin' })
          }
        })
        .catch(err => {
          console.error(err)
          this.error = err.message
        })
    }
  }
}
</script>
