<template>
  <div class="mobilenav">
    <div class="mobilenav__top">
      <RouterLink to="/" @click.native="$scroll.toTop(0, 275), $emit('closeNav')">
        <img src="/images/logo.svg" alt="PMPQ" class="navbar__logo" width="52" height="52" />
      </RouterLink>
      <div style="display: flex">
        <button class="button -icon -nav" @click="goToChart"><Icon id="heart" /></button>
        <button class="mobilenav__button -white" @click="$emit('closeNav')">
          <Icon id="close" class="mobilenav__button--icon -close" />
          Menu
        </button>
      </div>
    </div>
    <div>
      <ul>
        <li v-for="(label, key) in links" :key="key" :ref="key" :class="'mobilenav__link'" @click="$emit('closeNav')">
          <RouterLink v-if="key !== 'blog'" :to="{ name: 'home', hash: `#${key}` }">
            <Icon v-if="key === 'engagement'" id="heart" />
            {{ label }}
          </RouterLink>
          <RouterLink v-else :to="{ name: 'blog' }">{{ label }}</RouterLink>
        </li>
      </ul>
    </div>
    <div class="mobilenav__bottom">
      <a
        href="https://www.youtube.com/channel/UCVtRERklhHKX0VTGRpL-mIA"
        aria-label="Youtube"
        target="_blank"
        rel="noopener"
        class="mobilenav__social"
      >
        <Icon id="youtube" />
      </a>
      <a
        href="https://www.facebook.com/CIUSSSCN/"
        aria-label="Facebook"
        target="_blank"
        rel="noopener"
        class="mobilenav__social"
      >
        <Icon id="facebook" />
      </a>
      <a
        href="https://www.instagram.com/explore/tags/pourmoipourquebec"
        aria-label="Instagram"
        target="_blank"
        rel="noopener"
        class="mobilenav__social"
      >
        <Icon id="instagram" />
      </a>
      <a
        href="https://www.linkedin.com/company/ciusss-de-la-capitale-nationale/"
        aria-label="LinkedIn"
        target="_blank"
        rel="noopener"
        class="mobilenav__social"
      >
        <Icon id="linkedin" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteMobileNav',
  data() {
    return {
      links: {
        blog: 'Conseils du jour',
        engagement: 'Engagement',
        testimonials: 'Témoignages',
        stats: 'Tendance',
        references: 'Liens utiles'
      }
    }
  },
  methods: {
    goToChart() {
      if (this.$route.name === 'home') {
        this.$scroll.to(window.innerHeight * 2, 0, 250)
      } else {
        this.$router.replace({ name: 'home' }).then(() => {
          this.$scroll.to(window.innerHeight * 2, 0, 250)
        })
      }
      this.$emit('closeNav')
    }
  }
}
</script>
