import Element from './Element'
import Icon from './Icon.vue'
import DropDown from './DropDown.vue'
import ScrollBox from './ScrollBox.vue'
import Overlay from './Overlay.vue'
import PageLink from './PageLink.vue'
import RawHtml from './RawHtml.vue'

export default Vue =>
  [Element, Icon, DropDown, ScrollBox, Overlay, PageLink, RawHtml].forEach(component =>
    Vue.component(component.name, component)
  )
