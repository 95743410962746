<template>
  <div id="blog" class="blog">
    <div class="blog__header -center">
      <router-link :to="{ name: 'blog' }" class="blog__header--link">
        <Icon id="arrow_left" />
        Tous les conseils
      </router-link>
      <h1 v-if="article" class="blog__header--title">
        {{ article.title }}
      </h1>
    </div>
    <div v-if="article" class="blog__content -flex">
      <div class="blog__content--header">
        <img v-if="!article.youtube" :src="article.image_url" class="blog__article--img" />
        <div v-else class="videos__frame -blog">
          <iframe
            :src="`https://www.youtube.com/embed/${article.youtube}?controls=0&modestbranding=1`"
            class="videos__viewbox"
            title="Témoignage vidéo"
            frameborder="0"
            allowfullscreen
          />
        </div>

        <div v-if="hasNavigator && article.title" class="blog__article--share" @click="mobileShare">
          <Icon id="infinity" class="blog__article--share-icon" />
          <span style="margin-right: 1.5rem;">Partager ce conseil</span>
        </div>
        <div v-else-if="article.title" class="blog__article--share">
          <template v-for="(args, key) in sharePlatforms">
            <div v-if="Array.isArray(args)" :key="`share-${key}`" @click="share(args)">
              <Icon :id="key" class="blog__article--share-icon" />
            </div>
            <a v-else :key="`share-${key}`" :href="args">
              <Icon :id="key" class="blog__article--share-icon" />
            </a>
          </template>
        </div>
      </div>
      <div v-if="article" class="blog__article--date">{{ getDate(article.date) }}</div>
      <div class="blog__article">
        <RawHtml class="blog__article--html" :html="article.html" />
      </div>
      <div>
        <h3 class="blog__article--subtitle">
          Consultez d’autres conseils
        </h3>
        <div class="blog__article--scroll -preview">
          <router-link
            v-for="a in preview"
            :key="a.title"
            :to="{ name: 'article', params: { slug: a.slug } }"
            class="header-blog__card -scroll -preview"
          >
            <div class="header-blog__card--img" :style="`background-image: url(${a.image_url})`" />
            <div class="header-blog__card--title">
              {{ a.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <WaterMark />
  </div>
</template>

<script>
import { WaterMark } from '@/components'

export default {
  name: 'BlogArticle',
  components: { WaterMark },
  data() {
    return {
      articles: []
    }
  },
  metaInfo() {
    return {
      title: this.article.title,
      meta: [
        {
          name: 'description',
          content: this.article.description
        },
        { property: 'og:title', content: this.article.title },
        {
          property: 'og:description',
          content: this.article.description
        },
        {
          property: 'og:image',
          content: this.article.image_url
        }
      ]
    }
  },
  computed: {
    sharePlatforms() {
      return {
        facebook: [
          `https://www.facebook.com/dialog/share?app_id=381239146397050&display=popup&href=${window.location.href}&redirect_uri=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        twitter: [
          `https://twitter.com/intent/tweet?hashtags=PourMoiPourQuebec&text=${this.article.title}&url=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        linkedin: [
          `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        email: `mailto:?subject=${this.article.title}&body=${window.location.href}`
      }
    },
    preview() {
      const preview = JSON.parse(JSON.stringify(this.articles))
      const currentIndex = preview.findIndex(({ slug }) => slug === this.$route.params.slug)
      preview.splice(currentIndex, 1)

      return preview.slice(0, 3)
    },
    article() {
      const article = this.articles.find(({ slug }) => slug === this.$route.params.slug)
      return article || {}
    },
    hasNavigator() {
      if (navigator) {
        if (navigator.share) {
          if (window.innerWidth < window.innerHeight) return true
        }
      }
      return false
    }
  },
  watch: {
    $route(to, from) {
      if (to.params.slug !== from.params.slug) {
        this.$scroll.toTop(0, 275)
      }
    }
  },
  created() {
    if (this.$route.params.slug && this.articles.length === 0) {
      this.$firebase.db.ref('/blog').once('value', data => {
        this.articles = Object.values(data.val()).sort((a, b) => b.timestamp - a.timestamp)
      })
    }
  },
  methods: {
    share(args) {
      window.open(...args)
    },
    mobileShare() {
      if (navigator && navigator.share) {
        navigator.share({ url: window.location.href }).catch(console.error)
      } else {
        window.open(
          `https://www.facebook.com/dialog/share?app_id=381239146397050&display=popup&href=SOURCE_URL&redirect_uri=${window.location.href}`
        )
      }
    },
    getDate(date) {
      const result = new Date(date + 24 * 60 * 60 * 1000)
      return result.toLocaleString('fr', {
        weekday: 'long',
        day: 'numeric',
        month: 'long'
      })
    }
  }
}
</script>
