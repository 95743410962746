<template>
  <Element :tag="tag" class="raw-html">
    <slot />
  </Element>
</template>

<script>
import domPurify from 'dompurify'

export default {
  name: 'RawHtml',
  props: {
    tag: { type: String, default: 'div' },
    html: { type: [String, Object], default: Object }
  },
  computed: {
    htmlContent() {
      const { before = null, after = null } = typeof this.html === 'string' ? { after: this.html } : this.html
      return { afterbegin: before, beforeend: after }
    }
  },
  watch: {
    htmlContent: {
      immediate: true,
      handler() {
        this.$nextTick(() =>
          Object.entries(this.htmlContent).forEach(([position, content]) => {
            if (!content) return
            this.$el.innerHTML = ''
            this.$el.insertAdjacentHTML(position, domPurify.sanitize(content))

            Array.from(this.$el.querySelectorAll('a[href^="http"]')).forEach(externalLink => {
              externalLink.target = '_blank'
              externalLink.rel = 'noopener'
            })
            Array.from(this.$el.querySelectorAll('*')).forEach(el => {
              el.removeAttribute('style')
            })
          })
        )
      }
    }
  }
}
</script>
